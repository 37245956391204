import {
  animate,
  m,
  useMotionValue,
  ValueAnimationTransition,
} from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { isHotkeyPressed } from "react-hotkeys-hook";
import { parseHotkey } from "react-hotkeys-hook/src/parseHotkeys";

import { cn } from "~/lib/cn";

const keyMap = {
  period: ".",
  comma: ",",
  space: " ",
};

interface KbdKeyProps {
  value: string;
  flush?: boolean;
}
function KbdKey({ value, flush }: KbdKeyProps) {
  const [isPressed, setIsPressed] = useState(false);

  const scale = useMotionValue(1);

  const key = useMemo(() => {
    switch (value) {
      case "⌘":
        return "meta";
      case "⌥":
        return "alt";
      case "⇧":
        return "shift";
      case "Ctrl":
        return "ctrl";
      default:
        return value;
    }
  }, [value]);

  useEffect(() => {
    const transition: ValueAnimationTransition = {
      duration: 0.1,
      ease: "linear",
    };

    if (isPressed) {
      animate(scale, flush ? 1.05 : 0.95, transition);
    } else {
      animate(scale, 1, transition);
    }
  }, [flush, isPressed, scale]);

  useEffect(() => {
    const onKeyDown = () => setIsPressed(isHotkeyPressed(key, "||"));
    const onKeyUp = () => setIsPressed(false);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    window.addEventListener("blur", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
      window.removeEventListener("blur", onKeyUp);
    };
  }, [key]);

  return (
    <m.span
      className="flex items-center justify-center origin-center"
      style={{ scale }}
    >
      <kbd
        className={cn(
          "flex items-center justify-center transition duration-150",
          !flush && [
            "w-5 h-5 text-2xs bg-action border border-action rounded shadow text-primary/80",
            isPressed && "bg-subtle text-primary",
          ],
          flush && [
            "w-3 text-xs font-black text-secondary",
            isPressed && "text-primary",
          ],
        )}
      >
        <span className="align-baseline text-center font-medium leading-[110%] font-sans">
          {value}
        </span>
      </kbd>
    </m.span>
  );
}

export interface KbdProps {
  shortcut: string;
  flush?: boolean;
}

export function Kbd({ shortcut, flush }: KbdProps) {
  const hotkey = useMemo(() => parseHotkey(shortcut), [shortcut]);

  const keys = useMemo(() => {
    const keys: string[] = [];
    hotkey.keys?.forEach((key) => {
      const mappedKey =
        key in keyMap ? keyMap[key as keyof typeof keyMap] : key;
      keys.push(mappedKey.toLocaleUpperCase());
    });
    if (hotkey.shift) {
      keys.unshift("⇧");
    }
    if (hotkey.alt) {
      keys.unshift("⌥");
    }
    if (hotkey.ctrl) {
      keys.unshift("Ctrl");
    }
    if (hotkey.meta) {
      keys.unshift("⌘");
    }
    return keys;
  }, [hotkey]);

  const label = useMemo(() => {
    return keys
      .map((key) => {
        switch (key) {
          case "⌘":
            return "Command";
          case "⌥":
            return "Option";
          case "⇧":
            return "Shift";
          case "Ctrl":
            return "Ctrl";
          case ".":
            return "Period";
          case ",":
            return "Comma";
          case " ":
            return "Space";
          case "'":
            return "Quote";
          default:
            return key;
        }
      })
      .join(" ");
  }, [keys]);

  return (
    <span
      className={cn("flex items-center", {
        "gap-[3px]": !flush,
        "gap-1": flush,
      })}
      aria-label={label}
    >
      {keys.map((key) => (
        <KbdKey key={key} value={key} flush={flush} />
      ))}
    </span>
  );
}
