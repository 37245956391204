import { ApiModel } from "api-client";
import { createContext, useContext } from "react";

import { ActionContext } from "~/types";

export const ActionReactContext = createContext<ActionContext<any, any> | null>(
  null,
);

export function useActionReactContext<T extends ApiModel, ExtraContext = {}>() {
  const context = useContext(ActionReactContext);
  if (!context) {
    throw new Error(
      "useActionReactContext must be used within an ActionReactProvider",
    );
  }
  return context as ActionContext<T, ExtraContext>;
}
