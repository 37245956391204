import { ApiModel, CurrentUser } from "api-client";
import { ReactNode, SVGProps } from "react";
import { Location } from "react-router-dom";

import { Params, Path, useNavigate } from "~/router";

export type Prettify<T> = { [K in keyof T]: T[K] } & {};

export type NonUndefined<T> = T extends infer C | undefined
  ? C extends undefined
    ? never
    : C
  : T;

export type ActionContext<T extends ApiModel, ExtraContext = {}> = {
  target: T;
  currentUser: CurrentUser | null;
  location: Location;
  matchPath<P extends Path>(
    pathname: P,
  ): (P extends keyof Params ? Params[P] : {}) | null;
} & ExtraContext;

export class ActionEvent<T extends ApiModel, ExtraContext = {}> extends Event {
  context: ActionContext<T, ExtraContext>;

  constructor(context: ActionContext<T, ExtraContext>) {
    super("action", { cancelable: true });
    this.context = context;
  }
}

export type ActionVariant = "primary" | "danger";

export type CleanupFn = () => void;
export type ExecuteHelpers = {
  cleanup: CleanupFn;
  navigate: ReturnType<typeof useNavigate>;
  location: Location;
};

export interface Action<T extends ApiModel, ExtraContext = unknown> {
  /** A unique identifier for the action. */
  id: string;
  /** A unique identifier for the action's group. */
  group?: string;
  /** An icon to display in the action menu. */
  icon: (props: SVGProps<SVGSVGElement>) => ReactNode;
  /** Keyboard shortcut to trigger the action. */
  shortcut?: string;
  /** The action's label. */
  label: string | ((context: ActionContext<T, ExtraContext>) => string);
  /** Optional styling to apply to the action. */
  variant?:
    | ActionVariant
    | ((context: ActionContext<T, ExtraContext>) => ActionVariant);
  /** Whether the action is applicable in the current context. Applicable by default. */
  applicable?: (context: ActionContext<T, ExtraContext>) => boolean;
  /** Whether the action is disabled in the current context. Enabled by default. */
  disabled?: (context: ActionContext<T, ExtraContext>) => boolean;
  /** A function that executes the action. */
  execute: (
    event: ActionEvent<T, ExtraContext>,
    helpers: ExecuteHelpers,
  ) => Promise<ReactNode | void>;
}
