export function Logo() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.37872 4.48056L9.09793 1.6009C9.67397 1.35402 10.326 1.35402 10.9021 1.6009L17.6213 4.48056C18.1408 4.7032 18.1408 5.43967 17.6213 5.66232L10.9021 8.54198C10.326 8.78885 9.67397 8.78885 9.09793 8.54198L2.37872 5.66232C1.85922 5.43967 1.85922 4.7032 2.37872 4.48056Z"
        fill="currentColor"
      />
      <path
        d="M10.9576 12.0592C10.3499 12.3389 9.6502 12.3389 9.04248 12.0592L4.21431 9.83673L2.09744 10.744C1.82168 10.8621 1.64288 11.1333 1.64288 11.4333V14.715C1.64288 15.315 2.00048 15.8573 2.55201 16.0937L8.59373 18.683C9.49176 19.0679 10.5083 19.0679 11.4063 18.683L17.448 16.0937C17.9996 15.8573 18.3572 15.315 18.3572 14.715V11.4333C18.3572 11.1333 18.1784 10.8621 17.9026 10.744L15.7857 9.83673L10.9576 12.0592Z"
        fill="currentColor"
      />
    </svg>
  );
}
