import * as Tooltip from "@radix-ui/react-tooltip";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient, useUsersMeQuery } from "api-client";
import { LazyMotion } from "framer-motion";
import { Fragment, lazy, Suspense } from "react";
import { HotkeysProvider } from "react-hotkeys-hook";
import { Outlet } from "react-router-dom";

import { Toaster } from "~/components/Toaster";
import { useAuth } from "~/lib/auth";
import { Logo } from "~/svgs/Logo";

const loadFeatures = () =>
  import("~/lib/motion/features").then((res) => res.default);

const StaffBar = lazy(() => import("~/components/StaffBar"));

function RootLayout() {
  const status = useAuth();
  const { isPending, data: me } = useUsersMeQuery({
    enabled: status === "success",
  });

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-1">
        {status === "success" && isPending ? (
          <Fragment>
            <div className="min-w-64 bg-panel border-r border-primary" />
            <div className="flex-1 flex items-center justify-center bg-main">
              <div className="w-6 aspect-square text-icon">
                <Logo />
              </div>
            </div>
          </Fragment>
        ) : (
          <Outlet />
        )}
      </div>

      {(import.meta.env.DEV || me?.role === "staff") && (
        <Suspense fallback={null}>
          <StaffBar />
        </Suspense>
      )}
    </div>
  );
}

function RootLayoutWithQueryClient() {
  return (
    <HotkeysProvider initiallyActiveScopes={["*"]}>
      <QueryClientProvider client={queryClient}>
        <LazyMotion features={loadFeatures}>
          <Tooltip.Provider>
            <RootLayout />
            <Toaster />
          </Tooltip.Provider>
        </LazyMotion>
      </QueryClientProvider>
    </HotkeysProvider>
  );
}

export default RootLayoutWithQueryClient;
