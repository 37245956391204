import * as RadixTooltip from "@radix-ui/react-tooltip";
import { PropsWithChildren, ReactNode } from "react";

import { Kbd } from "~/components/Kbd";
import { cn } from "~/lib/cn";

export interface TooltipProps extends PropsWithChildren {
  label: string;
  trailingAccessory?: ReactNode;
  shortcut?: string;
  sideOffset?: number;
  align?: "start" | "center" | "end";
  side?: "top" | "bottom";
}

export function Tooltip({
  label,
  trailingAccessory,
  shortcut,
  children,
  side = "top",
  sideOffset = 6,
  align = "center",
}: TooltipProps) {
  return (
    <RadixTooltip.Root delayDuration={500} disableHoverableContent>
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          align={align}
          side={side}
          sideOffset={sideOffset}
          collisionPadding={8}
          className={cn(
            "dark bg-action z-50 rounded-md px-1.5 py-1 shadow border border-action",
            "data-[state=open]:animate-tooltip-enter",
            "data-[state=closed]:animate-tooltip-leave",
            "origin-[--radix-tooltip-content-transform-origin]",
            shortcut && "pl-2 pr-1",
          )}
        >
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
              <p className="text-xs font-medium">{label}</p>
              {trailingAccessory}
            </div>
            {shortcut && <Kbd shortcut={shortcut} />}
          </div>
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}
