import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60, // 1 minute
      staleTime: Infinity,
      retryOnMount: false,
      structuralSharing: false,
      retry(failureCount, error) {
        if (isAxiosError(error) && error.response?.status === 404) return false;
        return failureCount < 3;
      },
    },
  },
});
