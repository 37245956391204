import { PropsWithChildren, ReactNode } from "react";

export interface WrapProps extends PropsWithChildren<unknown> {
  condition: boolean;
  wrap(children: ReactNode): ReactNode;
}

export function Wrap({ condition, children, wrap }: WrapProps) {
  if (condition) {
    return wrap(children);
  } else {
    return children;
  }
}
