import {
  useOrganizationQuery,
  useOrganizationsApplicationQuery,
  useOrganizationsModelQuery,
  useOrganizationsModelsRecordQuery,
  useUsersMeQuery,
} from "api-client";

import { useParams } from "~/router";

import { useAuth } from "./auth";

export function useCurrentUser() {
  const status = useAuth();
  const { data: user } = useUsersMeQuery({
    enabled: status === "success",
  });
  return user ?? null;
}

export function useCurrentOrganization() {
  const params = useParams("/o/:org");
  const { data: organization } = useOrganizationQuery({ slug: params.org });
  return organization ?? null;
}

export function useCurrentApplication() {
  const appParams = useParams("/o/:org/a/:app");

  const model = useCurrentModel();

  const organizationSlug = appParams.org ?? model?.organization_slug;
  const slug = appParams.app ?? model?.application_slug;

  const applicationQuery = useOrganizationsApplicationQuery(
    { organizationSlug, slug },
    { enabled: !!organizationSlug && !!slug },
  );
  return applicationQuery.data ?? null;
}

export function useCurrentModel() {
  const params = useParams("/o/:org/a/:app/m/:model");
  const modelQuery = useOrganizationsModelQuery(
    {
      organizationSlug: params.org,
      id: params.model,
    },
    { enabled: !!params.org && !!params.model },
  );
  return modelQuery.data ?? null;
}

export function useCurrentRecord() {
  const params = useParams("/o/:org/a/:app/m/:model/r/:record");
  const recordQuery = useOrganizationsModelsRecordQuery(
    {
      organizationSlug: params.org,
      modelId: params.model,
      id: params.record,
    },
    { enabled: !!params.org && !!params.model && !!params.record },
  );
  return recordQuery.data ?? null;
}
