// This file is generated by api/lib/api_gen.rb
/* eslint-disable */

import axios from 'axios';
import { useQuery, useInfiniteQuery, queryOptions, infiniteQueryOptions, QueryKey } from '@tanstack/react-query';

export const PAGE_SIZE = 25;

export const KNOWN_FLAGS = ["record_sidebar", "views"] as const;

export const client = axios.create({
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
	},
});

export interface RequestOptions {
	signal: AbortSignal;
}

export interface PaginatedRequestOptions extends RequestOptions {
	pageParam: number | undefined;
}

export interface GetInviteParams {
	token: UrlParam;
}

export type GetInviteResponse = OrganizationInvite;

export async function getInvite(params: GetInviteParams, { signal }: RequestOptions): Promise<GetInviteResponse> {
  const res = await client.get(`/v1/invites/${params.token}`, { signal, params });
  return res.data;
}

export function inviteQueryOptions(params: GetInviteParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/invites/[token]`, params.token]) as QueryKey,
		queryFn: (options) => getInvite(params, options),
	})
}

export function useInviteQuery(params: GetInviteParams, options?: Partial<ReturnType<typeof inviteQueryOptions>>) {
	return useQuery({
		...inviteQueryOptions(params),
		...options
	});
}

export interface CreateInvitesAcceptParams {
	/** Username to assign the current user */
	username: string;
	inviteToken: UrlParam;
}

export type CreateInvitesAcceptResponse = void;

export async function createInvitesAccept(params: CreateInvitesAcceptParams): Promise<CreateInvitesAcceptResponse> {
  const res = await client.post(`/v1/invites/${params.inviteToken}/accept`, params);
  return res.data;
}

export interface GetAllOrganizationsParams {}

export type GetAllOrganizationsResponse = Pagination<Organization>;

export async function getAllOrganizations(params: GetAllOrganizationsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsResponse> {
  const res = await client.get(`/v1/organizations`, { signal, params: { page: pageParam } });
  return res.data;
}

export function organizationsInfiniteQueryOptions(params: GetAllOrganizationsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations`]) as QueryKey,
		queryFn: (options) => getAllOrganizations(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsInfiniteQuery(params: GetAllOrganizationsParams, options?: Partial<ReturnType<typeof organizationsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsPaginatedQueryOptions(params: GetAllOrganizationsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations`, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizations(params, { ...options, pageParam }),
	})
}

export function useOrganizationsPaginatedQuery(params: GetAllOrganizationsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationParams {
	name: string;
	slug: string;
	organization_membership: {
		/** Username to assign the current user */
		username: string
	};
}

export type CreateOrganizationResponse = OrganizationMembership;

export async function createOrganization(params: CreateOrganizationParams): Promise<CreateOrganizationResponse> {
  const res = await client.post(`/v1/organizations`, params);
  return res.data;
}

export interface GetOrganizationParams {
	slug: UrlParam;
}

export type GetOrganizationResponse = Organization;

export async function getOrganization(params: GetOrganizationParams, { signal }: RequestOptions): Promise<GetOrganizationResponse> {
  const res = await client.get(`/v1/organizations/${params.slug}`, { signal, params });
  return res.data;
}

export function organizationQueryOptions(params: GetOrganizationParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[slug]`, params.slug]) as QueryKey,
		queryFn: (options) => getOrganization(params, options),
	})
}

export function useOrganizationQuery(params: GetOrganizationParams, options?: Partial<ReturnType<typeof organizationQueryOptions>>) {
	return useQuery({
		...organizationQueryOptions(params),
		...options
	});
}

export interface GetAllOrganizationsApplicationsParams {
	organizationSlug: UrlParam;
}

export type GetAllOrganizationsApplicationsResponse = Pagination<Application>;

export async function getAllOrganizationsApplications(params: GetAllOrganizationsApplicationsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsApplicationsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/applications`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsApplicationsInfiniteQueryOptions(params: GetAllOrganizationsApplicationsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications`, params.organizationSlug]) as QueryKey,
		queryFn: (options) => getAllOrganizationsApplications(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsApplicationsInfiniteQuery(params: GetAllOrganizationsApplicationsParams, options?: Partial<ReturnType<typeof organizationsApplicationsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsApplicationsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsApplicationsPaginatedQueryOptions(params: GetAllOrganizationsApplicationsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications`, params.organizationSlug, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsApplications(params, { ...options, pageParam }),
	})
}

export function useOrganizationsApplicationsPaginatedQuery(params: GetAllOrganizationsApplicationsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsApplicationsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsApplicationsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationsApplicationParams {
	name: string;
	slug: string;
	visibility: "public" | "private";
	connection_url: string;
	api_key: string;
	organizationSlug: UrlParam;
}

export type CreateOrganizationsApplicationResponse = Application;

export async function createOrganizationsApplication(params: CreateOrganizationsApplicationParams): Promise<CreateOrganizationsApplicationResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/applications`, params);
  return res.data;
}

export interface GetOrganizationsApplicationParams {
	organizationSlug: UrlParam;
	slug: UrlParam;
}

export type GetOrganizationsApplicationResponse = Application;

export async function getOrganizationsApplication(params: GetOrganizationsApplicationParams, { signal }: RequestOptions): Promise<GetOrganizationsApplicationResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/applications/${params.slug}`, { signal, params });
  return res.data;
}

export function organizationsApplicationQueryOptions(params: GetOrganizationsApplicationParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications/[slug]`, params.organizationSlug, params.slug]) as QueryKey,
		queryFn: (options) => getOrganizationsApplication(params, options),
	})
}

export function useOrganizationsApplicationQuery(params: GetOrganizationsApplicationParams, options?: Partial<ReturnType<typeof organizationsApplicationQueryOptions>>) {
	return useQuery({
		...organizationsApplicationQueryOptions(params),
		...options
	});
}

export interface DeleteOrganizationsApplicationParams {
	organizationSlug: UrlParam;
	slug: UrlParam;
}

export type DeleteOrganizationsApplicationResponse = void;

export async function deleteOrganizationsApplication(params: DeleteOrganizationsApplicationParams): Promise<DeleteOrganizationsApplicationResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/applications/${params.slug}`);
  return res.data;
}

export interface GetAllOrganizationsApplicationsFavoritesParams {
	organizationSlug: UrlParam;
	applicationSlug: UrlParam;
}

export type GetAllOrganizationsApplicationsFavoritesResponse = Pagination<Favorite>;

export async function getAllOrganizationsApplicationsFavorites(params: GetAllOrganizationsApplicationsFavoritesParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsApplicationsFavoritesResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/applications/${params.applicationSlug}/favorites`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsApplicationsFavoritesInfiniteQueryOptions(params: GetAllOrganizationsApplicationsFavoritesParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications/[applicationSlug]/favorites`, params.organizationSlug, params.applicationSlug]) as QueryKey,
		queryFn: (options) => getAllOrganizationsApplicationsFavorites(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsApplicationsFavoritesInfiniteQuery(params: GetAllOrganizationsApplicationsFavoritesParams, options?: Partial<ReturnType<typeof organizationsApplicationsFavoritesInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsApplicationsFavoritesInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsApplicationsFavoritesPaginatedQueryOptions(params: GetAllOrganizationsApplicationsFavoritesParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications/[applicationSlug]/favorites`, params.organizationSlug, params.applicationSlug, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsApplicationsFavorites(params, { ...options, pageParam }),
	})
}

export function useOrganizationsApplicationsFavoritesPaginatedQuery(params: GetAllOrganizationsApplicationsFavoritesParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsApplicationsFavoritesPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsApplicationsFavoritesPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface UpdateOrganizationsApplicationsFavoritesReorderParams {
	items: {
		id: string
		position: number
	}[];
	organizationSlug: UrlParam;
	applicationSlug: UrlParam;
}

export type UpdateOrganizationsApplicationsFavoritesReorderResponse = void;

export async function updateOrganizationsApplicationsFavoritesReorder(params: UpdateOrganizationsApplicationsFavoritesReorderParams): Promise<UpdateOrganizationsApplicationsFavoritesReorderResponse> {
  const res = await client.put(`/v1/organizations/${params.organizationSlug}/applications/${params.applicationSlug}/favorites/reorder`, params);
  return res.data;
}

export interface DeleteOrganizationsApplicationsHmacSecretParams {
	organizationSlug: UrlParam;
	applicationSlug: UrlParam;
}

export type DeleteOrganizationsApplicationsHmacSecretResponse = void;

export async function deleteOrganizationsApplicationsHmacSecret(params: DeleteOrganizationsApplicationsHmacSecretParams): Promise<DeleteOrganizationsApplicationsHmacSecretResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/applications/${params.applicationSlug}/hmac_secret`);
  return res.data;
}

export interface CreateOrganizationsApplicationsHmacSecretParams {
	organizationSlug: UrlParam;
	applicationSlug: UrlParam;
}

export type CreateOrganizationsApplicationsHmacSecretResponse = {
	hmac_secret: string
};

export async function createOrganizationsApplicationsHmacSecret(params: CreateOrganizationsApplicationsHmacSecretParams): Promise<CreateOrganizationsApplicationsHmacSecretResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/applications/${params.applicationSlug}/hmac_secret`, params);
  return res.data;
}

export interface GetAllOrganizationsApplicationsModelsParams {
	organizationSlug: UrlParam;
	applicationSlug: UrlParam;
}

export type GetAllOrganizationsApplicationsModelsResponse = Pagination<Model>;

export async function getAllOrganizationsApplicationsModels(params: GetAllOrganizationsApplicationsModelsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsApplicationsModelsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/applications/${params.applicationSlug}/models`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsApplicationsModelsInfiniteQueryOptions(params: GetAllOrganizationsApplicationsModelsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications/[applicationSlug]/models`, params.organizationSlug, params.applicationSlug]) as QueryKey,
		queryFn: (options) => getAllOrganizationsApplicationsModels(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsApplicationsModelsInfiniteQuery(params: GetAllOrganizationsApplicationsModelsParams, options?: Partial<ReturnType<typeof organizationsApplicationsModelsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsApplicationsModelsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsApplicationsModelsPaginatedQueryOptions(params: GetAllOrganizationsApplicationsModelsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/applications/[applicationSlug]/models`, params.organizationSlug, params.applicationSlug, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsApplicationsModels(params, { ...options, pageParam }),
	})
}

export function useOrganizationsApplicationsModelsPaginatedQuery(params: GetAllOrganizationsApplicationsModelsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsApplicationsModelsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsApplicationsModelsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationsApplicationsSyncParams {
	organizationSlug: UrlParam;
	applicationSlug: UrlParam;
}

export type CreateOrganizationsApplicationsSyncResponse = void;

export async function createOrganizationsApplicationsSync(params: CreateOrganizationsApplicationsSyncParams): Promise<CreateOrganizationsApplicationsSyncResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/applications/${params.applicationSlug}/sync`, params);
  return res.data;
}

export interface GetAllOrganizationsInvitesParams {
	organizationSlug: UrlParam;
}

export type GetAllOrganizationsInvitesResponse = Pagination<OrganizationInvite>;

export async function getAllOrganizationsInvites(params: GetAllOrganizationsInvitesParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsInvitesResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/invites`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsInvitesInfiniteQueryOptions(params: GetAllOrganizationsInvitesParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/invites`, params.organizationSlug]) as QueryKey,
		queryFn: (options) => getAllOrganizationsInvites(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsInvitesInfiniteQuery(params: GetAllOrganizationsInvitesParams, options?: Partial<ReturnType<typeof organizationsInvitesInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsInvitesInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsInvitesPaginatedQueryOptions(params: GetAllOrganizationsInvitesParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/invites`, params.organizationSlug, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsInvites(params, { ...options, pageParam }),
	})
}

export function useOrganizationsInvitesPaginatedQuery(params: GetAllOrganizationsInvitesParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsInvitesPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsInvitesPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationsInviteParams {
	recipient_email: string;
	organizationSlug: UrlParam;
}

export type CreateOrganizationsInviteResponse = OrganizationInvite;

export async function createOrganizationsInvite(params: CreateOrganizationsInviteParams): Promise<CreateOrganizationsInviteResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/invites`, params);
  return res.data;
}

export interface DeleteOrganizationsInviteParams {
	organizationSlug: UrlParam;
	id: UrlParam;
}

export type DeleteOrganizationsInviteResponse = void;

export async function deleteOrganizationsInvite(params: DeleteOrganizationsInviteParams): Promise<DeleteOrganizationsInviteResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/invites/${params.id}`);
  return res.data;
}

export interface GetAllOrganizationsMembersParams {
	organizationSlug: UrlParam;
}

export type GetAllOrganizationsMembersResponse = Pagination<OrganizationMember>;

export async function getAllOrganizationsMembers(params: GetAllOrganizationsMembersParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsMembersResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/members`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsMembersInfiniteQueryOptions(params: GetAllOrganizationsMembersParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/members`, params.organizationSlug]) as QueryKey,
		queryFn: (options) => getAllOrganizationsMembers(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsMembersInfiniteQuery(params: GetAllOrganizationsMembersParams, options?: Partial<ReturnType<typeof organizationsMembersInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsMembersInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsMembersPaginatedQueryOptions(params: GetAllOrganizationsMembersParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/members`, params.organizationSlug, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsMembers(params, { ...options, pageParam }),
	})
}

export function useOrganizationsMembersPaginatedQuery(params: GetAllOrganizationsMembersParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsMembersPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsMembersPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface DeleteOrganizationsMemberParams {
	organizationSlug: UrlParam;
	id: UrlParam;
}

export type DeleteOrganizationsMemberResponse = void;

export async function deleteOrganizationsMember(params: DeleteOrganizationsMemberParams): Promise<DeleteOrganizationsMemberResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/members/${params.id}`);
  return res.data;
}

export interface GetOrganizationsModelParams {
	organizationSlug: UrlParam;
	id: UrlParam;
}

export type GetOrganizationsModelResponse = Model;

export async function getOrganizationsModel(params: GetOrganizationsModelParams, { signal }: RequestOptions): Promise<GetOrganizationsModelResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.id}`, { signal, params });
  return res.data;
}

export function organizationsModelQueryOptions(params: GetOrganizationsModelParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[id]`, params.organizationSlug, params.id]) as QueryKey,
		queryFn: (options) => getOrganizationsModel(params, options),
	})
}

export function useOrganizationsModelQuery(params: GetOrganizationsModelParams, options?: Partial<ReturnType<typeof organizationsModelQueryOptions>>) {
	return useQuery({
		...organizationsModelQueryOptions(params),
		...options
	});
}

export interface DeleteOrganizationsModelsFavoriteParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type DeleteOrganizationsModelsFavoriteResponse = void;

export async function deleteOrganizationsModelsFavorite(params: DeleteOrganizationsModelsFavoriteParams): Promise<DeleteOrganizationsModelsFavoriteResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/favorites`);
  return res.data;
}

export interface CreateOrganizationsModelsFavoriteParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type CreateOrganizationsModelsFavoriteResponse = Favorite;

export async function createOrganizationsModelsFavorite(params: CreateOrganizationsModelsFavoriteParams): Promise<CreateOrganizationsModelsFavoriteResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/favorites`, params);
  return res.data;
}

export interface GetAllOrganizationsModelsFieldsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type GetAllOrganizationsModelsFieldsResponse = Pagination<ModelField>;

export async function getAllOrganizationsModelsFields(params: GetAllOrganizationsModelsFieldsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsFieldsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/fields`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsFieldsInfiniteQueryOptions(params: GetAllOrganizationsModelsFieldsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/fields`, params.organizationSlug, params.modelId]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsFields(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsFieldsInfiniteQuery(params: GetAllOrganizationsModelsFieldsParams, options?: Partial<ReturnType<typeof organizationsModelsFieldsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsFieldsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsFieldsPaginatedQueryOptions(params: GetAllOrganizationsModelsFieldsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/fields`, params.organizationSlug, params.modelId, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsFields(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsFieldsPaginatedQuery(params: GetAllOrganizationsModelsFieldsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsFieldsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsFieldsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface GetAllOrganizationsModelsFieldsRecordsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	fieldName: UrlParam;
}

export type GetAllOrganizationsModelsFieldsRecordsResponse = Pagination<ModelRecord>;

export async function getAllOrganizationsModelsFieldsRecords(params: GetAllOrganizationsModelsFieldsRecordsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsFieldsRecordsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/fields/${params.fieldName}/records`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsFieldsRecordsInfiniteQueryOptions(params: GetAllOrganizationsModelsFieldsRecordsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/fields/[fieldName]/records`, params.organizationSlug, params.modelId, params.fieldName]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsFieldsRecords(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsFieldsRecordsInfiniteQuery(params: GetAllOrganizationsModelsFieldsRecordsParams, options?: Partial<ReturnType<typeof organizationsModelsFieldsRecordsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsFieldsRecordsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsFieldsRecordsPaginatedQueryOptions(params: GetAllOrganizationsModelsFieldsRecordsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/fields/[fieldName]/records`, params.organizationSlug, params.modelId, params.fieldName, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsFieldsRecords(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsFieldsRecordsPaginatedQuery(params: GetAllOrganizationsModelsFieldsRecordsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsFieldsRecordsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsFieldsRecordsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface GetAllOrganizationsModelsRecordsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type GetAllOrganizationsModelsRecordsResponse = Pagination<ModelRecord>;

export async function getAllOrganizationsModelsRecords(params: GetAllOrganizationsModelsRecordsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsRecordsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsRecordsInfiniteQueryOptions(params: GetAllOrganizationsModelsRecordsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records`, params.organizationSlug, params.modelId]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecords(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsRecordsInfiniteQuery(params: GetAllOrganizationsModelsRecordsParams, options?: Partial<ReturnType<typeof organizationsModelsRecordsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsRecordsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsRecordsPaginatedQueryOptions(params: GetAllOrganizationsModelsRecordsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records`, params.organizationSlug, params.modelId, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecords(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsRecordsPaginatedQuery(params: GetAllOrganizationsModelsRecordsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsRecordsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsRecordsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationsModelsRecordParams {
	record: ModelRecord;
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type CreateOrganizationsModelsRecordResponse = ModelRecord;

export async function createOrganizationsModelsRecord(params: CreateOrganizationsModelsRecordParams): Promise<CreateOrganizationsModelsRecordResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records`, params);
  return res.data;
}

export interface GetOrganizationsModelsRecordParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	id: UrlParam;
}

export type GetOrganizationsModelsRecordResponse = ModelRecord;

export async function getOrganizationsModelsRecord(params: GetOrganizationsModelsRecordParams, { signal }: RequestOptions): Promise<GetOrganizationsModelsRecordResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.id}`, { signal, params });
  return res.data;
}

export function organizationsModelsRecordQueryOptions(params: GetOrganizationsModelsRecordParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[id]`, params.organizationSlug, params.modelId, params.id]) as QueryKey,
		queryFn: (options) => getOrganizationsModelsRecord(params, options),
	})
}

export function useOrganizationsModelsRecordQuery(params: GetOrganizationsModelsRecordParams, options?: Partial<ReturnType<typeof organizationsModelsRecordQueryOptions>>) {
	return useQuery({
		...organizationsModelsRecordQueryOptions(params),
		...options
	});
}

export interface UpdateOrganizationsModelsRecordParams {
	record: ModelRecord;
	organizationSlug: UrlParam;
	modelId: UrlParam;
	id: UrlParam;
}

export type UpdateOrganizationsModelsRecordResponse = ModelRecord;

export async function updateOrganizationsModelsRecord(params: UpdateOrganizationsModelsRecordParams): Promise<UpdateOrganizationsModelsRecordResponse> {
  const res = await client.put(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.id}`, params);
  return res.data;
}

export interface DeleteOrganizationsModelsRecordParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	id: UrlParam;
}

export type DeleteOrganizationsModelsRecordResponse = void;

export async function deleteOrganizationsModelsRecord(params: DeleteOrganizationsModelsRecordParams): Promise<DeleteOrganizationsModelsRecordResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.id}`);
  return res.data;
}

export interface GetAllOrganizationsModelsRecordsEventsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	recordId: UrlParam;
}

export type GetAllOrganizationsModelsRecordsEventsResponse = Pagination<Event>;

export async function getAllOrganizationsModelsRecordsEvents(params: GetAllOrganizationsModelsRecordsEventsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsRecordsEventsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.recordId}/events`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsRecordsEventsInfiniteQueryOptions(params: GetAllOrganizationsModelsRecordsEventsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[recordId]/events`, params.organizationSlug, params.modelId, params.recordId]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecordsEvents(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsRecordsEventsInfiniteQuery(params: GetAllOrganizationsModelsRecordsEventsParams, options?: Partial<ReturnType<typeof organizationsModelsRecordsEventsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsRecordsEventsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsRecordsEventsPaginatedQueryOptions(params: GetAllOrganizationsModelsRecordsEventsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[recordId]/events`, params.organizationSlug, params.modelId, params.recordId, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecordsEvents(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsRecordsEventsPaginatedQuery(params: GetAllOrganizationsModelsRecordsEventsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsRecordsEventsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsRecordsEventsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface DeleteOrganizationsModelsRecordsFavoriteParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	recordId: UrlParam;
}

export type DeleteOrganizationsModelsRecordsFavoriteResponse = void;

export async function deleteOrganizationsModelsRecordsFavorite(params: DeleteOrganizationsModelsRecordsFavoriteParams): Promise<DeleteOrganizationsModelsRecordsFavoriteResponse> {
  const res = await client.delete(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.recordId}/favorites`);
  return res.data;
}

export interface CreateOrganizationsModelsRecordsFavoriteParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	recordId: UrlParam;
}

export type CreateOrganizationsModelsRecordsFavoriteResponse = Favorite;

export async function createOrganizationsModelsRecordsFavorite(params: CreateOrganizationsModelsRecordsFavoriteParams): Promise<CreateOrganizationsModelsRecordsFavoriteResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.recordId}/favorites`, params);
  return res.data;
}

export interface GetAllOrganizationsModelsRecordsFieldsRecordsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	recordId: UrlParam;
	fieldName: UrlParam;
}

export type GetAllOrganizationsModelsRecordsFieldsRecordsResponse = Pagination<ModelRecord>;

export async function getAllOrganizationsModelsRecordsFieldsRecords(params: GetAllOrganizationsModelsRecordsFieldsRecordsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsRecordsFieldsRecordsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.recordId}/fields/${params.fieldName}/records`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions(params: GetAllOrganizationsModelsRecordsFieldsRecordsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[recordId]/fields/[fieldName]/records`, params.organizationSlug, params.modelId, params.recordId, params.fieldName]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecordsFieldsRecords(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsRecordsFieldsRecordsInfiniteQuery(params: GetAllOrganizationsModelsRecordsFieldsRecordsParams, options?: Partial<ReturnType<typeof organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsRecordsFieldsRecordsPaginatedQueryOptions(params: GetAllOrganizationsModelsRecordsFieldsRecordsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[recordId]/fields/[fieldName]/records`, params.organizationSlug, params.modelId, params.recordId, params.fieldName, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecordsFieldsRecords(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsRecordsFieldsRecordsPaginatedQuery(params: GetAllOrganizationsModelsRecordsFieldsRecordsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsRecordsFieldsRecordsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsRecordsFieldsRecordsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface GetAllOrganizationsModelsRecordsViewsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	recordId: UrlParam;
}

export type GetAllOrganizationsModelsRecordsViewsResponse = Pagination<View>;

export async function getAllOrganizationsModelsRecordsViews(params: GetAllOrganizationsModelsRecordsViewsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsRecordsViewsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.recordId}/views`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsRecordsViewsInfiniteQueryOptions(params: GetAllOrganizationsModelsRecordsViewsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[recordId]/views`, params.organizationSlug, params.modelId, params.recordId]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecordsViews(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsRecordsViewsInfiniteQuery(params: GetAllOrganizationsModelsRecordsViewsParams, options?: Partial<ReturnType<typeof organizationsModelsRecordsViewsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsRecordsViewsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsRecordsViewsPaginatedQueryOptions(params: GetAllOrganizationsModelsRecordsViewsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/records/[recordId]/views`, params.organizationSlug, params.modelId, params.recordId, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsRecordsViews(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsRecordsViewsPaginatedQuery(params: GetAllOrganizationsModelsRecordsViewsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsRecordsViewsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsRecordsViewsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationsModelsRecordsViewParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
	recordId: UrlParam;
}

export type CreateOrganizationsModelsRecordsViewResponse = View;

export async function createOrganizationsModelsRecordsView(params: CreateOrganizationsModelsRecordsViewParams): Promise<CreateOrganizationsModelsRecordsViewResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/records/${params.recordId}/views`, params);
  return res.data;
}

export interface GetAllOrganizationsModelsViewsParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type GetAllOrganizationsModelsViewsResponse = Pagination<View>;

export async function getAllOrganizationsModelsViews(params: GetAllOrganizationsModelsViewsParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllOrganizationsModelsViewsResponse> {
  const res = await client.get(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/views`, { signal, params: { ...params, page: pageParam } });
  return res.data;
}

export function organizationsModelsViewsInfiniteQueryOptions(params: GetAllOrganizationsModelsViewsParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/views`, params.organizationSlug, params.modelId]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsViews(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useOrganizationsModelsViewsInfiniteQuery(params: GetAllOrganizationsModelsViewsParams, options?: Partial<ReturnType<typeof organizationsModelsViewsInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...organizationsModelsViewsInfiniteQueryOptions(params),
		...options
	});
}
export function organizationsModelsViewsPaginatedQueryOptions(params: GetAllOrganizationsModelsViewsParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/organizations/[organizationSlug]/models/[modelId]/views`, params.organizationSlug, params.modelId, pageParam]) as QueryKey,
		queryFn: (options) => getAllOrganizationsModelsViews(params, { ...options, pageParam }),
	})
}

export function useOrganizationsModelsViewsPaginatedQuery(params: GetAllOrganizationsModelsViewsParams, pageParam: number, options?: Partial<ReturnType<typeof organizationsModelsViewsPaginatedQueryOptions>>) {
	return useQuery({
		...organizationsModelsViewsPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateOrganizationsModelsViewParams {
	organizationSlug: UrlParam;
	modelId: UrlParam;
}

export type CreateOrganizationsModelsViewResponse = View;

export async function createOrganizationsModelsView(params: CreateOrganizationsModelsViewParams): Promise<CreateOrganizationsModelsViewResponse> {
  const res = await client.post(`/v1/organizations/${params.organizationSlug}/models/${params.modelId}/views`, params);
  return res.data;
}

export interface UpdateOrganizationsViewParams {
	organizationSlug: UrlParam;
	id: UrlParam;
}

export type UpdateOrganizationsViewResponse = View;

export async function updateOrganizationsView(params: UpdateOrganizationsViewParams): Promise<UpdateOrganizationsViewResponse> {
  const res = await client.put(`/v1/organizations/${params.organizationSlug}/views/${params.id}`, params);
  return res.data;
}

export interface CreatePusherAuthParams {
	channel_name: string;
	socket_id: string;
}

export type CreatePusherAuthResponse = PusherAuth;

export async function createPusherAuth(params: CreatePusherAuthParams): Promise<CreatePusherAuthResponse> {
  const res = await client.post(`/v1/pusher/auth`, params);
  return res.data;
}

export interface CreateUsersAuthenticateParams {
	email: string;
	password: string;
}

export type CreateUsersAuthenticateResponse = CurrentUser;

export async function createUsersAuthenticate(params: CreateUsersAuthenticateParams): Promise<CreateUsersAuthenticateResponse> {
  const res = await client.post(`/v1/users/authenticate`, params);
  return res.data;
}

export interface GetUsersMeParams {}

export type GetUsersMeResponse = CurrentUser;

export async function getUsersMe(params: GetUsersMeParams, { signal }: RequestOptions): Promise<GetUsersMeResponse> {
  const res = await client.get(`/v1/users/me`, { signal });
  return res.data;
}

export function usersMeQueryOptions(params: GetUsersMeParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/users/me`]) as QueryKey,
		queryFn: (options) => getUsersMe(params, options),
	})
}

export function useUsersMeQuery(params: GetUsersMeParams, options?: Partial<ReturnType<typeof usersMeQueryOptions>>) {
	return useQuery({
		...usersMeQueryOptions(params),
		...options
	});
}

export interface CreateUsersSignOutParams {
	user_id: string | null;
}

export type CreateUsersSignOutResponse = void;

export async function createUsersSignOut(params: CreateUsersSignOutParams): Promise<CreateUsersSignOutResponse> {
  const res = await client.post(`/v1/users/sign_out`, params);
  return res.data;
}

export interface CreateUsersSwitchParams {
	user_id: string;
}

export type CreateUsersSwitchResponse = CurrentUser;

export async function createUsersSwitch(params: CreateUsersSwitchParams): Promise<CreateUsersSwitchResponse> {
  const res = await client.post(`/v1/users/switch`, params);
  return res.data;
}

export interface CreateUsersVerifyParams {
	code: string;
}

export type CreateUsersVerifyResponse = void;

export async function createUsersVerify(params: CreateUsersVerifyParams): Promise<CreateUsersVerifyResponse> {
  const res = await client.post(`/v1/users/verify`, params);
  return res.data;
}

export interface CreateUsersResetPasswordParams {
	token: string;
	password: string;
}

export type CreateUsersResetPasswordResponse = void;

export async function createUsersResetPassword(params: CreateUsersResetPasswordParams): Promise<CreateUsersResetPasswordResponse> {
  const res = await client.post(`/v1/users/reset_password`, params);
  return res.data;
}

export interface CreateUsersForgotPasswordParams {
	email: string;
}

export type CreateUsersForgotPasswordResponse = void;

export async function createUsersForgotPassword(params: CreateUsersForgotPasswordParams): Promise<CreateUsersForgotPasswordResponse> {
  const res = await client.post(`/v1/users/forgot_password`, params);
  return res.data;
}

export interface CreateUsersResendVerificationParams {}

export type CreateUsersResendVerificationResponse = void;

export async function createUsersResendVerification(params: CreateUsersResendVerificationParams): Promise<CreateUsersResendVerificationResponse> {
  const res = await client.post(`/v1/users/resend_verification`);
  return res.data;
}

export interface GetAllUsersParams {}

export type GetAllUsersResponse = Pagination<CurrentUser>;

export async function getAllUsers(params: GetAllUsersParams, { pageParam, signal }: PaginatedRequestOptions): Promise<GetAllUsersResponse> {
  const res = await client.get(`/v1/users`, { signal, params: { page: pageParam } });
  return res.data;
}

export function usersInfiniteQueryOptions(params: GetAllUsersParams) {
	return infiniteQueryOptions({
		queryKey: Object.freeze([`/v1/users`]) as QueryKey,
		queryFn: (options) => getAllUsers(params, options),
		initialPageParam: undefined as number | undefined,
		getNextPageParam: (lastPage) => lastPage.meta.next_page
	})
}

export function useUsersInfiniteQuery(params: GetAllUsersParams, options?: Partial<ReturnType<typeof usersInfiniteQueryOptions>>) {
	return useInfiniteQuery({
		...usersInfiniteQueryOptions(params),
		...options
	});
}
export function usersPaginatedQueryOptions(params: GetAllUsersParams, pageParam: number) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/users`, pageParam]) as QueryKey,
		queryFn: (options) => getAllUsers(params, { ...options, pageParam }),
	})
}

export function useUsersPaginatedQuery(params: GetAllUsersParams, pageParam: number, options?: Partial<ReturnType<typeof usersPaginatedQueryOptions>>) {
	return useQuery({
		...usersPaginatedQueryOptions(params, pageParam),
		...options
	});
}

export interface CreateUserParams {
	email: string;
	password: string;
	first_name: string;
	last_name: string;
}

export type CreateUserResponse = CurrentUser;

export async function createUser(params: CreateUserParams): Promise<CreateUserResponse> {
  const res = await client.post(`/v1/users`, params);
  return res.data;
}

export interface GetUserParams {
	id: UrlParam;
}

export type GetUserResponse = User;

export async function getUser(params: GetUserParams, { signal }: RequestOptions): Promise<GetUserResponse> {
  const res = await client.get(`/v1/users/${params.id}`, { signal, params });
  return res.data;
}

export function userQueryOptions(params: GetUserParams) {
	return queryOptions({
		queryKey: Object.freeze([`/v1/users/[id]`, params.id]) as QueryKey,
		queryFn: (options) => getUser(params, options),
	})
}

export function useUserQuery(params: GetUserParams, options?: Partial<ReturnType<typeof userQueryOptions>>) {
	return useQuery({
		...userQueryOptions(params),
		...options
	});
}

export interface Application {
	type: "Application";
	id: string;
	slug: string;
	name: string;
	visibility: "public" | "private";
	models_count: number;
	pusher_channel: `private-${string}`;
	has_hmac_secret: boolean;
	creator: OrganizationMember;
	organization_slug: string;
	viewer_can_sync: boolean;
	latest_sync: ApplicationSync | null;
	syncs_count: number;
	created_at: string;
	updated_at: string;
}

export interface BaseApplicationSync {
	type: "ApplicationSync";
	id: string;
	application_slug: string;
	requester: OrganizationMember;
	created_at: string;
	updated_at: string;
}

export interface ApplicationSyncPendingView extends BaseApplicationSync {
	state: "pending";
}

export interface ApplicationSyncConnectingView extends BaseApplicationSync {
	state: "connecting";
	connecting_at: string;
}

export interface ApplicationSyncSyncingView extends BaseApplicationSync {
	state: "syncing";
	sdk_version: string;
	sdk_type: "rails";
	sdk_request_verified: boolean;
	connecting_at: string;
	syncing_at: string;
}

export interface ApplicationSyncAnalyzingView extends BaseApplicationSync {
	state: "analyzing";
	sdk_version: string;
	sdk_type: "rails";
	sdk_request_verified: boolean;
	connecting_at: string;
	syncing_at: string;
	analyzing_at: string;
}

export interface ApplicationSyncSucceededView extends BaseApplicationSync {
	state: "succeeded";
	sdk_version: string;
	sdk_type: "rails";
	sdk_request_verified: boolean;
	connecting_at: string;
	syncing_at: string;
	analyzing_at: string;
	succeeded_at: string;
}

export interface ApplicationSyncFailedView extends BaseApplicationSync {
	state: "failed";
	sdk_version: string | null;
	sdk_type: "rails" | null;
	sdk_request_verified: boolean | null;
	connecting_at: string | null;
	syncing_at: string | null;
	analyzing_at: string | null;
	failed_at: string;
	failure_reason: string;
}

export type ApplicationSync = ApplicationSyncPendingView | ApplicationSyncConnectingView | ApplicationSyncSyncingView | ApplicationSyncAnalyzingView | ApplicationSyncSucceededView | ApplicationSyncFailedView;

export interface CurrentUser {
	type: "CurrentUser";
	id: string;
	first_name: string | null;
	last_name: string | null;
	full_name: string;
	avatar_url: string;
	email: string;
	joined_at: string;
	updated_at: string;
	role: "member" | "staff";
	email_verified: boolean;
	pusher_channel: `private-${string}`;
	feature_flags: FeatureFlag[];
	organization_memberships: OrganizationMembership[];
}

export interface BaseEvent {
	type: "Event";
	id: string;
	created_at: string;
	updated_at: string;
}

export interface EventModelRecordActionView extends BaseEvent {
	eventable: ModelRecordAction;
}

export type Event = EventModelRecordActionView;

export interface BaseFavorite {
	type: "Favorite";
	id: string;
	created_at: string;
}

export interface FavoriteModelRecordView extends BaseFavorite {
	favoritable: ModelRecord;
}

export interface FavoriteModelView extends BaseFavorite {
	favoritable: Model;
}

export type Favorite = FavoriteModelRecordView | FavoriteModelView;

export interface FeatureFlag {
	type: "FeatureFlag";
	id: string;
	name: "record_sidebar" | "views" | (string & {});
}

export interface ModelFieldEnumOption {
	type: "ModelFieldEnumOption";
	value: string;
	position: number;
}

export interface BaseModelField {
	type: "ModelField";
	id: string;
	name: string;
	names: {
		humanized: string
		humanized_singular: string
		humanized_plural: string
		camelized: string
		camelized_singular: string
		camelized_plural: string
		titleized: string
		titleized_singular: string
		titleized_plural: string
	};
	model_id: string;
	organization_slug: string;
	field_name: string;
	nullable: boolean;
	read_only: boolean;
	show_on_index: boolean;
	hidden: boolean;
}

export interface ModelFieldOneReferenceView extends BaseModelField {
	field_type: "reference";
	reference_type: "to_one";
	default: Record<string, any> | null;
	models: [Model, ...Model[]];
	inverse_of: ModelField | null;
}

export interface ModelFieldManyReferenceView extends BaseModelField {
	field_type: "reference";
	reference_type: "to_many";
	default: Record<string, any>[];
	models: [Model, ...Model[]];
}

export interface ModelFieldStringView extends BaseModelField {
	field_type: "string";
	enum_options: ModelFieldEnumOption[];
	min_length: number | null;
	max_length: number | null;
	default: string | null;
}

export interface ModelFieldNumberView extends BaseModelField {
	field_type: "number";
	min: number | null;
	max: number | null;
	step: number | null;
	default: number | null;
}

export interface ModelFieldDateView extends BaseModelField {
	field_type: "date";
	specificity: "date" | "time" | "datetime";
	min: string | null;
	max: string | null;
	default: string | null;
}

export interface ModelFieldBooleanView extends BaseModelField {
	field_type: "boolean";
	default: boolean | null;
}

export interface ModelFieldJsonView extends BaseModelField {
	field_type: "json";
	default: Record<string, any> | null;
}

export type ModelField = ModelFieldOneReferenceView | ModelFieldManyReferenceView | ModelFieldStringView | ModelFieldNumberView | ModelFieldDateView | ModelFieldBooleanView | ModelFieldJsonView;

export interface BaseModelRecordAction {
	type: "ModelRecordAction";
	id: string;
	action_type: "create" | "update" | "delete";
	affected_field_names: string[];
}

export interface ModelRecordActionOrganizationMembershipView extends BaseModelRecordAction {
	actor: OrganizationMember;
}

export interface ModelRecordActionSystemView extends BaseModelRecordAction {
	actor: null;
}

export type ModelRecordAction = ModelRecordActionOrganizationMembershipView | ModelRecordActionSystemView;

export interface ModelRecord {
	type: "ModelRecord";
	organization_slug: string;
	application_slug: string;
	model_id: string;
	model_name: string;
	id: string;
	title: string | null;
	thumbnail: string | null;
	created_at: string | null;
	updated_at: string | null;
	data: Record<string, any>;
	viewer_favorited_at: string | null;
	pusher_channel: `private-${string}`;
	pusher_presence_channel: `presence-${string}`;
}

export interface Model {
	type: "Model";
	id: string;
	name: string;
	names: {
		singular: string
		camel_singular: string
		plural: string
		camel_plural: string
	};
	model_name: string;
	fields_count: number;
	icon: "user" | "user_group" | "attachment" | "key" | "bell" | "globe" | "heart" | "setting" | "commit" | "download" | "passcode" | "list" | "text" | "activity" | "app" | "field" | "model" | "data" | "item" | "flag" | "target";
	primary_key_field_id: string | null;
	title_field_id: string | null;
	thumbnail_field_id: string | null;
	created_at_field_id: string | null;
	updated_at_field_id: string | null;
	organization_slug: string;
	application_slug: string;
	pusher_presence_channel: `presence-${string}`;
	viewer_favorited_at: string | null;
}

export interface OrganizationInvite {
	type: "OrganizationInvite";
	id: string;
	organization: Organization;
	recipient_email: string;
	expires_at: string;
}

export interface OrganizationMember {
	type: "OrganizationMember";
	id: string;
	username: string;
	organization_slug: string;
	user: User;
}

export interface OrganizationMembership {
	type: "OrganizationMembership";
	id: string;
	username: string;
	user_id: string;
	organization: Organization;
	feature_flags: FeatureFlag[];
}

export interface Organization {
	type: "Organization";
	id: string;
	slug: string;
	name: string;
	sso_enabled: boolean;
	members_count: number;
	applications_count: number;
}

export interface Pagination<T extends object> {
	type: "Pagination";
	data: T[];
	meta: {
		current_page: number
		next_page: number | null
		total_pages: number
		per_page: number
		total_count: number
	};
}

export interface PusherApplicationSyncUpdate {
	type: "PusherApplicationSyncUpdate";
	event_name: "application_sync_update";
	data: ApplicationSync;
}

export interface PusherEventCreate {
	type: "PusherEventCreate";
	event_name: "event_create";
	data: Event;
}

export interface PusherAuth {
	type: "PusherAuth";
	auth: string;
	channel_data: string;
}

export interface User {
	type: "User";
	id: string;
	first_name: string | null;
	last_name: string | null;
	full_name: string;
	avatar_url: string;
	email: string;
	joined_at: string;
	updated_at: string;
}

export interface View {
	type: "View";
	id: string;
	viewer: OrganizationMember;
	created_at: string;
	updated_at: string;
}


export type UrlParam = string | number;

export type ApiModel =
	| Application
	| ApplicationSync
	| CurrentUser
	| Event
	| Favorite
	| FeatureFlag
	| ModelFieldEnumOption
	| ModelField
	| ModelRecordAction
	| ModelRecord
	| Model
	| OrganizationInvite
	| OrganizationMember
	| OrganizationMembership
	| Organization
	| Pagination<any>
	| PusherApplicationSyncUpdate
	| PusherEventCreate
	| PusherAuth
	| User
	| View;

