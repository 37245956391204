import { Link } from "~/router";
import { Logo } from "~/svgs/Logo";

function NotFound() {
  return (
    <div className="flex-1 flex flex-col items-center p-4">
      <div className="flex-1 flex gap-1 flex-col items-center justify-center">
        <h1 className="text-xl font-medium">Page not found</h1>
        <p className="text-sm text-secondary">
          Let's get you{" "}
          <Link to="/" className="text-primary">
            back to safety
          </Link>
        </p>
      </div>

      <div className="flex items-center">
        <Link to="/" className="w-6 aspect-square text-icon-subtle">
          <Logo />
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
