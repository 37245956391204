import { ComponentType, useCallback, useEffect, useState } from "react";

import { Navigate, Path } from "~/router";

import { pubsub } from "./pubsub";

export type AuthStatus = "none" | "pending" | "success";

export function useAuth() {
  const getAuthenticatedStatus = useCallback(() => {
    // Cookie "_baselayer_authenticated" value can be true, pending, or undefined
    const value = document.cookie
      .split("; ")
      .find((row) => {
        return row.startsWith("_baselayer_authenticated=");
      })
      ?.split("=")
      .at(1);

    if (value === "true") {
      return "success";
    } else if (value === "pending") {
      return "pending";
    } else {
      return "none";
    }
  }, []);

  const [status, setStatus] = useState<AuthStatus>(getAuthenticatedStatus);

  useEffect(() => {
    const onAuthStateChange = () => {
      setStatus(getAuthenticatedStatus());
    };
    pubsub.on("AUTH_STATE_CHANGE", onAuthStateChange);
    return () => {
      pubsub.off("AUTH_STATE_CHANGE", onAuthStateChange);
    };
  }, [getAuthenticatedStatus]);

  return status;
}

export type AuthType =
  | "unauthenticated"
  | "authenticated"
  | "pending_or_authenticated"
  | "public";

export interface WithAuthParams {
  type: AuthType;
  redirect?: Path;
}

export function withAuth<P>(
  Component: ComponentType<P>,
  params: WithAuthParams,
) {
  return function AuthComponent(props: P) {
    const status = useAuth();

    if (
      status === "none" &&
      (params.type === "authenticated" ||
        params.type === "pending_or_authenticated")
    ) {
      return <Navigate to={params.redirect ?? ("/signin" as any)} replace />;
    }

    if (status === "success" && params.type === "unauthenticated") {
      return <Navigate to={params.redirect ?? ("/" as any)} replace />;
    }

    return <Component {...(props as any)} />;
  };
}
