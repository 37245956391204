import {
  ApiModel,
  client,
  organizationQueryOptions,
  organizationsApplicationQueryOptions,
  organizationsModelQueryOptions,
  organizationsModelsRecordQueryOptions,
  userQueryOptions,
} from "./generated/api";
import { queryClient } from "./query";

export function cache(data: unknown) {
  const isApiModel =
    data && typeof data === "object" && !Array.isArray(data) && "type" in data;

  if (!isApiModel) return;

  const apiModel = data as ApiModel;
  switch (apiModel.type) {
    case "Application": {
      const query = organizationsApplicationQueryOptions({
        organizationSlug: apiModel.organization_slug,
        slug: apiModel.slug,
      });
      queryClient.setQueryData(query.queryKey, apiModel);
      break;
    }

    case "CurrentUser": {
      cache({ ...apiModel, type: "User" });
      break;
    }

    case "Favorite": {
      cache(apiModel.favoritable);
      break;
    }

    case "Model": {
      const query = organizationsModelQueryOptions({
        organizationSlug: apiModel.organization_slug,
        id: apiModel.id,
      });
      queryClient.setQueryData(query.queryKey, apiModel);
      break;
    }

    case "ModelRecord": {
      const query = organizationsModelsRecordQueryOptions({
        organizationSlug: apiModel.organization_slug,
        modelId: apiModel.model_id,
        id: apiModel.id,
      });
      queryClient.setQueryData(query.queryKey, apiModel);
      // Cache sub-records
      Object.keys(apiModel.data).forEach((key) => {
        if (typeof apiModel.data[key] === "object") {
          cache(apiModel.data[key]);
        }
      });
      break;
    }

    case "Organization": {
      const query = organizationQueryOptions({ slug: apiModel.slug });
      queryClient.setQueryData(query.queryKey, apiModel);
      break;
    }

    case "Pagination": {
      apiModel.data.forEach(cache);
      break;
    }

    case "User": {
      const query = userQueryOptions({ id: apiModel.id });
      queryClient.setQueryData(query.queryKey, apiModel);
      break;
    }
  }
}

client.interceptors.response.use(function (response) {
  cache(response.data);
  return response;
});
